import { useEffect, useState, useMemo } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import debounce from 'lodash.debounce'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { TagIcon } from '@heroicons/react/24/solid'
import Spin from '@components/icons/common/Spin'
import { useUI } from '@components/ui/context'
import { useCommerce } from '@commerce/index'
import { Picture, Text } from '@components/ui'
import { BRAND } from '@shopify/const'
import usePrice, { formatPrice } from '@shopify/product/use-price'
import { useUpdateItem, useRemoveItem } from 'lib/shopify/api/cart'
import { ProductQuantity, ProductPrice } from '@components/product'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { atobID, getThemeUrl } from '@lib/utils/tools'
import s from './CartItem.module.css'
import BannerLink from '@components/common/BannerLink'

const placeholderImg = '/product-img-placeholder.svg'

const CartItem = ({
  item,
  bundleItem,
  currencyCode,
  shopCommon = {},
  mainProduct = '',
  discountPrice,
  modal = 'default',
  position,
  allSku,
  getBundleSize,
  quantityBreakDescription,
  removeItemCbk,
  quantityBreakDiscountData,
  ...rest
}) => {
  const { locale } = useRouter()
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  //根据sku限购需求，最大最小值从shop里配置的sku里获取
  const { shop } = useCommerce()
  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[item?.variant?.sku]
  const limitMin = orderLimit?.min !== undefined ? orderLimit?.min : 1
  item.quantity = item.quantity >= limitMin ? item.quantity : limitMin
  const [quantity, setQuantity] = useState(item?.quantity)
  const removeItem = useRemoveItem()
  const updateItem = useUpdateItem({
    item,
  })

  const [amount, baseAmount] = useMemo(() => {
    return [
      Number(item?.totalAmount || item?.price),
      Number(item?.subtotalAmount || item?.price),
    ]
  }, [item.totalAmount, item.subtotalAmount])
  const [errorTip, setErrorTip] = useState('')
  const errorHandle = (error, curQuantity) => {
    setErrorTip(error)
    setQuantity('')
    setTimeout(() => {
      setQuantity(curQuantity)
    }, 10)
    const errorTimer = setTimeout(() => {
      setErrorTip('')
      clearTimeout(errorTimer)
    }, 8000)
  }
  const { price, basePrice, free } = usePrice({
    amount: discountPrice && discountPrice > 0 ? Number(discountPrice) : amount,
    baseAmount: baseAmount,
    currencyCode,
  })

  const { metafields = {} } = item?.variant

  const handleChange = async (value) => {
    if (!bundleItem) setQuantity(Number(value))
    if (item?.quantity === value || errorTip) return false
    try {
      await updateItem({
        quantity: Number(value),
        customAttributes: item?.customAttributes || [],
      })
      setErrorTip('')
    } catch (err) {
      console.warn('change updateCart err', err)
      errorHandle(err?.message || 'updateCart err', item.quantity)
    }
  }

  const increaseQuantity = async (n = 1) => {
    const curQuantity = item.quantity
    if (bundleItem) {
      const val = Number(item.quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('bundle updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    } else {
      const val = Number(quantity) + n
      setQuantity(val)
      await updateItem({
        quantity: val,
        customAttributes: item?.customAttributes || [],
      })
        .then(() => {
          setErrorTip('')
        })
        .catch((err) => {
          console.warn('updateCart err', err, item.quantity)
          errorHandle(err?.message || 'updateCart err', curQuantity)
        })
    }
    if (rest?.form === 'CartSidebarView') {
      pageGTMEvent({
        event: 'eeEvent',
        eventCategory: 'EnhancedEcommerce',
        eventAction: n > 0 ? 'Added Product' : 'Removed Product',
        nonInteraction: false,
        ecommerce: {
          currencyCode: currencyCode,
          ...(n > 0
            ? {
                add: {
                  products: [
                    {
                      id: item.variant.sku,
                      name: item.name,
                      price: price,
                      quantity: item.quantity,
                      variant: item.variant.name,
                      brand: BRAND,
                      category: '',
                    },
                  ],
                },
              }
            : {
                remove: {
                  products: [
                    {
                      id: item.variant.sku,
                      name: item.name,
                      price: price,
                      quantity: item.quantity,
                      variant: item.variant.name,
                      brand: BRAND,
                      category: '',
                    },
                  ],
                },
              }),
        },
      })
      pageGTMEvent({
        // GA4
        event: n > 0 ? 'add_to_cart' : 'remove_from_cart',
        page_group: document.location.href,
        position:
          n > 0 ? 'Shopping Cart Sidebar' : 'Shopping Cart Sidebar_Minus',
        ecommerce: {
          currency: currencyCode,
          value: price,
          items: [
            {
              item_id: atobID(item.id),
              item_name: item.name,
              item_brand: BRAND,
              item_variant: item.variant.name,
              price: price,
              quantity: item.quantity,
            },
          ],
        },
      })
      n > 0 &&
        pageGTMEvent({
          event: 'Add to Cart',
          page_group: document.location.href,
          position: 'Shopping Cart Sidebar',
          ecommerce: {
            currency: currencyCode,
            value: price,
            items: [
              {
                item_id: atobID(item.id),
                item_name: item.name,
                item_brand: BRAND,
                item_variant: item.variant.name,
                item_category: item.productType,
                price: price,
                quantity: item.quantity,
              },
            ],
          },
        })
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      removeItemCbk()
      if (rest?.form === 'CartSidebarView') {
        pageGTMEvent({
          event: 'eeEvent',
          eventCategory: 'EnhancedEcommerce',
          eventAction: 'Removed Product',
          nonInteraction: false,
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [
                {
                  id: item.variant.sku,
                  name: item.name,
                  price: price,
                  quantity: item.quantity,
                  variant: item?.variant?.name,
                  position: rest?.position,
                  brand: BRAND,
                  category: '',
                },
              ],
            },
          },
        })
      }
    } catch (error) {
      setRemoving(false)
    }
  }

  const options = useMemo(() => {
    return item.options
  }, [item.options, item?.variant?.options, modal])

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, item?.quantity])

  const handleNameClick = () => {
    closeSidebarIfPresent()
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_title',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bundle',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','),
        position: 'Cart Pop Up_Bundle_title',
        SKU: item?.variant?.sku,
        SKU_name: item?.name,
        SUM: price,
        SKU_variant: item?.variant?.name,
        SKU_index: position,
      },
    })
  }

  const handleImgClick = () => {
    closeSidebarIfPresent()

    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'Cart Pop Up_Bundle_image',
      eventAction: allSku?.join(','),
      eventLabel: item?.variant?.sku,
    })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bundle',
      event_parameters: {
        page_group: 'Cart Pop Up_' + allSku?.join(','),
        position: 'Cart Pop Up_Bundle_image',
        SKU: item?.variant?.sku,
        SKU_name: item?.name,
        SUM: price,
        SKU_variant: item?.variant?.name,
        SKU_index: position,
      },
    })
  }

  const link =
    (BRAND === 'eufy'
      ? getThemeUrl('/products' + item.path, locale)
      : `${locale === 'us' ? '' : '/' + locale}/products${item.path}`) +
    `?variant=${atobID(item?.variant?.id || product?.variants[0]?.id)}`

  const giveawayItem = item?.customAttributes?.find(
    (attribute) =>
      attribute.key === '_giveaway' ||
      attribute.key === '_giveaway_summerCampaignDiscount' ||
      attribute.key === '_giveaway_gradient_gifts'
  )
  return (
    <li
      className={cn(s.root, {
        'pointer-events-none opacity-50': removing,
      })}
      {...rest}
    >
      <div className="flex h-full items-stretch justify-between space-x-2">
        <div className="flex">
          {/* 产品图 */}
          <div className="relative self-center">
            <Picture
              className={cn(s.productImage)}
              source={item.variant.image?.url || placeholderImg}
              alt={item.variant.image?.altText || 'Product Image'}
            />
            <BannerLink to={link} onClick={handleImgClick} />
          </div>
          {/* 产品信息 */}
          <div className="grid h-fit grid-flow-row gap-2 text-base">
            {/* 产品名 */}
            <a
              tag="Auto-10000421"
              href={link}
              onClick={handleNameClick}
              className={s.productName}
              dangerouslySetInnerHTML={{
                __html: item.name || item.product_title,
              }}
            />
            {/* 产品选项 */}
            {options && options.length > 0 && (
              <div className="">
                {options?.map(
                  (option, i) =>
                    !option?.value?.includes('Default Title') && (
                      <div
                        key={`${item.id}-${option.name}`}
                        className="text-sm text-[#999]"
                        tag="Auto-10000422"
                      >
                        {option.name}:
                        <span className="ml-1 overflow-hidden">
                          {option.value}
                        </span>
                        {i === options.length - 1 ? (
                          ''
                        ) : (
                          <span className="mr-3" />
                        )}
                      </div>
                    )
                )}
              </div>
            )}
            <div className="py-1">
              {item?.discountAllocations &&
                item?.discountAllocations?.map((item, i) => {
                  const amountFormat = formatPrice({
                    amount: item.amount * -1,
                    currencyCode: currencyCode,
                    locale,
                  })
                  return (
                    <div
                      className="flex text-sm"
                      key={`order-${item?.code}-${i}`}
                    >
                      <div className="flex items-center">
                        <TagIcon
                          width={14}
                          height={14}
                          className="-mt-[2px] mr-1"
                        />
                        <Text
                          variant="inline"
                          html={item.code}
                          className="align-middle"
                        />
                      </div>
                      <span className="ml-1">({amountFormat})</span>
                    </div>
                  )
                })}
            </div>
            {/* 数量 controller */}
            {modal === 'default' && !giveawayItem && (
              <ProductQuantity
                s={{ quantityBox: s.quantityBox }}
                className={s.quantity}
                value={quantity}
                pId={item.variant.sku}
                increase={(num) => increaseQuantity(num || 1)}
                decrease={(num) => increaseQuantity(num || -1)}
                handleChange={debounce(handleChange, 500)}
                availableForSale={item.variant.availableForSale}
                currentlyNotInStock={item.variant.currentlyNotInStock}
                max={item.variant?.quantityAvailable || 0}
                shopCommon={shopCommon}
                form={rest?.form}
              />
            )}
            {metafields.cartInfo && (
              <div className={s.cartInfo}>
                <Text html={metafields.cartInfo}></Text>
              </div>
            )}
          </div>
        </div>
        {modal === 'default' && (!giveawayItem || amount != 0) && (
          <button
            tag="Auto-10000425"
            aria-label="remove"
            className="h-fit rounded-full bg-gray-100 p-1"
            onClick={handleRemove}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        )}
      </div>
      {/* 默认购物车价格在图片和产品信息下方 */}
      <div
        className={cn(
          'flex flex-wrap items-center justify-end pt-2 text-lg md:text-base'
        )}
      >
        <ProductPrice
          className={s.productPrice}
          styles={s}
          free={free}
          price={price}
          basePrice={basePrice}
          availableForSale={item.variant?.availableForSale}
          settings={shopCommon}
          autoTestTag="Auto-10000424"
        />
      </div>
      {/* 满减折扣描述 */}
      {item.product?.tags?.includes(quantityBreakDiscountData?.productTag) &&
        !giveawayItem &&
        quantityBreakDescription}
      {orderLimits?.show && errorTip && (
        <Text html={errorTip} className={s.errorTip}></Text>
      )}
    </li>
  )
}

export default CartItem
