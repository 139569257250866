import React, { useCallback, useState, useRef, useEffect } from 'react'
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import debounce from 'lodash.debounce'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useCommerce } from '@commerce/index'
import styles from './ProductQuantity.module.css'
import { Text, useUI } from '@components/ui'

const ProductQuantity = ({
  className,
  value,
  increase,
  decrease,
  handleChange,
  availableForSale,
  max,
  min = 1,
  step = 1,
  settings = {},
  pId,
  s: gs = {},
  cns = {},
  skipDebounce,
  shopCommon = {},
  pageSource,
  form,
  pageType,
}) => {
  const s = { ...styles, ...gs }
  const [minBubble, setMinBubble] = useState(false)
  const [maxBubble, setMaxBubble] = useState(false)
  const inputDom = useRef()
  const stock = settings?.infos?.stock
  const tipNumber = stock?.tip_number || 50
  const tipText = stock?.tip_text?.replace('{number}', max)
  const { couponInListing, setLstingCouponData, inViewDom, setInViewData } =
    useUI()

  max = availableForSale
    ? max <= 0
      ? shopCommon?.quantityMax || 9999
      : max
    : 0
  //根据sku限购需求，最大最小值从shop里配置的sku里获取
  const { shop } = useCommerce()
  const orderLimits = shop?.orderLimits
  const orderLimit = orderLimits?.limits?.[pId]
  min =
    orderLimit?.min !== undefined && orderLimit?.min >= min
      ? orderLimit?.min
      : min >= orderLimit?.max
      ? orderLimit?.max
      : min
  max =
    orderLimit?.max !== undefined && orderLimit?.max <= max
      ? orderLimit?.max
      : max
  const [numValue, setNum] = useState(value <= min ? min : value || 1)
  const formatNum = (num) => {
    let curNum = typeof num === 'string' && num?.replace(/\D/g, '')
    return Number((curNum > max ? max : curNum * 1) || min)
  }
  const minBubbleText =
    settings?.minBubbleText || orderLimits?.tips?.minBubbleText
  const maxBubbleText =
    settings?.maxBubbleText || orderLimits?.tips?.maxBubbleText

  // 增加或减少商品ga4埋点
  const handleCrease = useCallback(
    debounce(
      (action, num) => {
        pageGTMEvent({
          event: 'change_quantity',
          page_group: 'Product Detail Page_' + pId,
          action: action,
        })
        action === 'Plus'
          ? increase && increase(num)
          : decrease && decrease(num)
      },
      500,
      { leading: false }
    ),
    [pId, decrease, increase]
  )

  useEffect(() => {
    inputDom.current.value = numValue
  }, [numValue])

  useEffect(() => {
    setNum(value <= min ? min : value >= max ? max : value)
  }, [value])

  const handleQuickAct = (e, nums) => {
    e.stopPropagation()
    const current = Number(numValue) + nums
    const actionNum = current - Number(value)
    if (actionNum === 0) return
    if (actionNum !== 0 && current >= min && current <= max) {
      setNum(current)
      if (skipDebounce) {
        handleChange(current, 'Plus')
      } else {
        handleCrease('Plus', actionNum)
      }
    } else {
      setNum(max)
      if (skipDebounce) {
        handleChange(max, 'Plus')
      } else {
        handleCrease('Plus', max - Number(value)) // actionNum
      }
    }
    pageGTMEvent({
      event: 'uaEvent',
      eventCategory: 'bundle_color_engine',
      eventAction: pId, //变量，取该listing的SKU
      eventLabel: 'add', //减少取less
      eventValue: current, //数字，取框内数字
    })
    pageGTMEvent({ event_parameters: null })
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'bundle',
      event_parameters: {
        page_group: 'Product Detail Page_' + pId, //取该listing的SKU
        position: 'bundle_pla+filament',
        action: 'color', //减少取less
        SKU_variant: current, //数字，取框内数字
      },
    })
  }
  return (
    <div
      className={cn(
        className,
        {
          [s.root]: !className,
        },
        'flex flex-col items-center l:items-end l:gap-y-4 min-l:gap-y-5',
        {
          'gap-4 md:items-start min-md:!flex-row': pageSource === 'small_b',
        }
      )}
    >
      <div className={s.quantityBox}>
        <button
          type="button"
          aria-label="decrease quantity"
          onClick={(e) => {
            e.stopPropagation()
            const current = Number(numValue) - step
            const actionNum = current - Number(value)
            if (actionNum !== 0 && current >= min) {
              setNum(current)
              if (skipDebounce) {
                handleChange(current, 'Minus')
              } else {
                handleCrease('Minus', actionNum)
              }
            }
            pageGTMEvent({
              event: 'uaEvent',
              eventCategory: 'bundle_color_engine',
              eventAction: pId, //变量，取该listing的SKU
              eventLabel: 'less', //减少取less
              eventValue: current, //数字，取框内数字
            })
            pageGTMEvent({ event_parameters: null })
            pageGTMEvent({
              event: 'ga4Event',
              event_name: 'bundle',
              event_parameters: {
                page_group: 'Product Detail Page_' + pId, //取该listing的SKU
                position: 'bundle_pla+filament',
                action: 'color', //减少取less
                SKU_variant: current, //数字，取框内数字
              },
            })
          }}
          className={cn(s.actions, s.leftActions, {
            [s.buttonDisabled]: numValue <= min,
          })}
          onMouseEnter={() => {
            if (value <= min) {
              setMinBubble(true)
            }
          }}
          onMouseLeave={() => {
            setMinBubble(false)
          }}
        >
          {minBubble && availableForSale && minBubbleText && (
            <Bubble text={minBubbleText?.replace('{min}', min)} s={s} />
          )}
          <MinusIcon width={20} height={20} />
        </button>
        <label className={s.inputBox}>
          <input
            tag="Auto-10000423"
            ref={inputDom}
            className={cn(s.input, cns.input)}
            disabled={step > 1}
            onKeyUp={(e) => {
              let num = e.target.value
              if (num !== '') {
                e.target.value = formatNum(e.target.value)
              }
            }}
            onBlur={(e) => {
              //只在失去焦点时改变quantity值
              const value = e.target.value
              const num =
                value === '' || (value !== '' && value <= min) ? min : value
              e.target.value = num
              setNum(formatNum(num))
              handleChange(formatNum(num), 'Change')
            }}
            type="number"
            max={max}
            min={min}
          />
        </label>

        <button
          type="button"
          aria-label="increase quantity"
          onClick={(e) => {
            e.stopPropagation()
            const current = Number(numValue) + step
            const actionNum = current - Number(value)
            if (actionNum !== 0 && current >= min && current <= max) {
              setNum(current)
              if (skipDebounce) {
                handleChange(current, 'Plus')
              } else {
                handleCrease('Plus', actionNum)
              }
            }
            pageGTMEvent({
              event: 'uaEvent',
              eventCategory: 'bundle_color_engine',
              eventAction: pId, //变量，取该listing的SKU
              eventLabel: 'add', //减少取less
              eventValue: current, //数字，取框内数字
            })
            pageGTMEvent({ event_parameters: null })
            pageGTMEvent({
              event: 'ga4Event',
              event_name: 'bundle',
              event_parameters: {
                page_group: 'Product Detail Page_' + pId, //取该listing的SKU
                position: 'bundle_pla+filament',
                action: 'color', //减少取less
                SKU_variant: current, //数字，取框内数字
              },
            })
          }}
          className={cn(s.actions, s.rightActions, {
            [s.buttonDisabled]: numValue < min || numValue >= max,
          })}
          style={{ marginLeft: '-1px' }}
          onMouseEnter={() => {
            if (numValue >= max) {
              setMaxBubble(true)
            }
          }}
          onMouseLeave={() => {
            setMaxBubble(false)
          }}
        >
          {maxBubble && availableForSale && maxBubbleText && (
            <Bubble text={maxBubbleText?.replace('{max}', max)} s={s} />
          )}
          <PlusIcon width={20} height={20} />
        </button>
      </div>

      {/*  小b页面 || (小充 && 有counpop的listing展示 & 侧边栏不展示) */}
      {(pageSource === 'small_b' ||
        (form !== 'CartSidebarView' &&
          pageType === 'charger' &&
          couponInListing?.codeVal)) && (
        <div
          className={cn(s.quickActions, {
            '!mt-0': (pageSource = 'small_b'),
          })}
        >
          {[5, 10, 50, 100].map((item) => (
            <span
              key={item}
              className={s.quickItem}
              onClick={(e) => handleQuickAct(e, item)}
            >
              + {item}
            </span>
          ))}
        </div>
      )}

      {max < tipNumber && availableForSale && tipText && (
        <div className="pt-2 text-red">
          <span>{tipText}</span>
        </div>
      )}
    </div>
  )
}

export default ProductQuantity

const Bubble = ({ text, s }) => {
  return <Text html={text} className={s.speechBubble} variant="paragraph" />
}
